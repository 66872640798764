import './App.css';
import {
    App, f7ready, View
} from "framework7-react";
import Marketplace from "./Screens/Marketplace";
import Favourites from "./Screens/Favourites";
import CarCard from "./Screens/CarCard";
import Loader from "./Screens/Loader";
import Register from "./Screens/Register";
import Startup from "./Screens/Startup";
import ChangeLang from "./Screens/ChangeLang";
import Filter from "./Screens/Filter";


function MyApp() {
    f7ready(() => {
    });
    const f7params = {
        name: 'My App',
        routes: [
            {
                path: '/loader',
                component: Loader,
            },
            {
                path: '/register',
                component: Register,
                options: {
                    clearPreviousHistory: true
                }

            },
            {
                path: '/market',
                component: Marketplace,
                options: {
                    animate: false,
                    clearPreviousHistory: true
                }
            },
            {
                path: '/favourites',
                component: Favourites,
                options: {
                    animate: false,
                    clearPreviousHistory: true
                }
            },
            {
                path: '/carcard/:id/',
                component: CarCard,
                options: {
                    clearPreviousHistory: true
                }

            },
            {
                path: '/startup',
                component: Startup,
                options: {
                    clearPreviousHistory: true
                }

            },
            {
                path: '/lang',
                component: ChangeLang,
                options: {
                    clearPreviousHistory: true
                }

            },
            {
                path: '/filter',
                component: Filter,
                options: {
                    clearPreviousHistory: true
                }

            },
        ],
    };
  return (
      <App theme="ios" name="My App" {...f7params}>
          <View main url="/loader" />
      </App>
  );
}

export default MyApp;
