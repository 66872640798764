import React, {useEffect, useState} from 'react';
import {BlockTitle, Button, f7, List, ListItem, Page, Range} from "framework7-react";
import {useTranslation} from "react-i18next";
import {fetchQueryCars} from "../api/cars";

const Filter = () => {
    const {i18n, t} = useTranslation();
    const [brandOptions, setBrandOptions] = useState(JSON.parse(localStorage.getItem('brandOptions')));
    const [carModel, setCarModel] = useState(localStorage.getItem('model') || '');

    const [brand, setBrand] = useState(localStorage.getItem('brand') || '');
    const [priceMin, setPriceMin] = useState(parseInt(localStorage.getItem('priceMin')) || 0);
    const [priceMax, setPriceMax] = useState(parseInt(localStorage.getItem('priceMax')) || 0);

    const onPriceChange = (values) => {
        console.log(values[1])
        setPriceMin(values[0]);
        setPriceMax(values[1]);
    };

    const applyFilter = () => {
        const query = `${brand && `brand=${brand}&`}${priceMin && `min_price=${priceMin}&`}${priceMax && `max_price=${priceMax}&`}${carModel && `model=${carModel}`}`
        localStorage.setItem('brand', brand);
        localStorage.setItem('model', carModel);
        localStorage.setItem('priceMin', priceMin);
        localStorage.setItem('priceMax', priceMax);

        f7.views.main.router.navigate('/market', {props: {query}})
    }

    const resetFilter = () => {
        localStorage.removeItem('brand')
        localStorage.removeItem('priceMin')
        localStorage.removeItem('priceMax')
        localStorage.removeItem('model')

        f7.views.main.router.navigate('/market')

    }


    const getModelsForSelectedBrand = (brandId) => {
        const selectedBrand = brandOptions.find(b => b.id === parseInt(brandId));
        return selectedBrand ? selectedBrand.models : [];
    };

    const handleBackBtn = () => {
        f7.view.main.router.navigate('/market')
    }
    useEffect(() => {
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);

        return (() => {
            window.Telegram.WebApp.BackButton.hide();
        })
    }, [])
    return (
        <Page>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2, fontWeight: 'bold'}}>{t('filter')}</BlockTitle>
            <List style={{marginTop: 20}} strongIos dividersIos insetIos>
                <ListItem
                    smartSelectParams={{
                        openIn: 'popup',
                        inputIconPosition: 'end',
                        cssClass: "strongIos dividersIos insetIos",
                        closeOnSelect: true,
                        searchbar: true,
                        searchbarDisableButton: false,
                        appendSearchbarNotFound: 'Ничего не найдено',
                        searchbarPlaceholder: 'Search brand',
                    }}
                    title={t('brand')}
                    smartSelect
                >
                    <select value={brand} onChange={(e) => {setBrand(e.target.value); setCarModel('');}}>
                        <option value=""></option>
                        {brandOptions.map(brandOption => (
                            <option
                                key={brandOption.id}
                                value={brandOption.id}>{brandOption.name}
                            </option>
                        ))}
                    </select>
                </ListItem>
            </List>
            {brand && (
                <div>
                    <List key={`${brand}-model-list`} style={{ marginTop: -10 }} strongIos dividersIos insetIos>
                        <ListItem
                            smartSelectParams={{
                                openIn: 'popup',
                                inputIconPosition: 'end',
                                cssClass: "strongIos dividersIos insetIos",
                                closeOnSelect: true,
                                searchbar: true,
                                searchbarDisableButton: false,
                                appendSearchbarNotFound: 'Ничего не найдено',
                                searchbarPlaceholder: 'Search model',
                            }}
                            title={t('model')}
                            smartSelect
                        >
                            <select value={carModel} onChange={(e) => {setCarModel(e.target.value);}}>
                                <option disabled value=""></option>
                                {getModelsForSelectedBrand(brand).map(model => (
                                    <option key={model.id} value={model.id}>{model.name}</option>
                                ))}
                            </select>
                        </ListItem>
                    </List>


                </div>
            )}

            <BlockTitle className="display-flex justify-content-space-between">
                {t('costRange')}{' '}
                <span>
          ${priceMin} - ${priceMax}
        </span>
            </BlockTitle>
            <List style={{ overflow: 'visible' }} strongIos dividersIos insetIos>
                <ListItem style={{overflow: 'visible', padding: '10px 5px' }}>
                    <Range
                        className="custom-range-no-clip"
                        min={0}
                        max={200000}
                        step={1000}
                        value={[priceMin, priceMax]}
                        dual={true}
                        color="green"
                        onRangeChange={onPriceChange}
                    />
                </ListItem>
            </List>
            <Button onClick={() => {applyFilter()}}  style={{borderRadius: '14px', textTransform: 'none', fontWeight: 'bold',}} className="margin-left margin-right " fill large>{t('apply')}</Button>
            <Button onClick={() => {resetFilter()}} style={{marginTop: '5px', color: '#FF3B30',borderRadius: '14px', textTransform: 'none', fontWeight: 'bold', marginRight: 100, marginLeft: 100}} large>{t('reset')}</Button>
        </Page>
    );
};

export default Filter;
