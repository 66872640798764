import axios from "axios";


export const getHandbook = async (type) => {
    try {
        const res = await axios.get(`https://dev.selluzauto.uz/api/generic/${type}/`)
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const Login = async (data) => {
    try {
        delete axios.defaults.headers.common['Authorization'];

        const res = await axios.post('https://dev.selluzauto.uz/api/marketplace/login/', data);

        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const updateClient = async (data) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.put('https://dev.selluzauto.uz/api/marketplace/client/', data);

        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const getClient = async () => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.get('https://dev.selluzauto.uz/api/marketplace/client/');

        return res
    } catch (e) {
        console.log(e)
    }
}

export const doBargain = async (data) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.post('https://dev.selluzauto.uz/api/marketplace/client/bargain/',data );

        return res
    } catch (e) {
        console.log(e)
    }
}


