
import {
    App,
    BlockFooter, BlockHeader,
    BlockTitle, f7, f7ready,
    Link,
    List,
    ListInput, ListItem, Page,
} from "framework7-react";
import React, {useCallback, useEffect, useReducer, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../store/store";
import {updateClient} from "../api/user";
import {useTranslation} from "react-i18next";

function Register() {
    f7ready(() => {
    });

    const {t, i18n} = useTranslation();

    const dispatch = useDispatch()

    const [birthday, setBirthday] = useState('')
    const initialState = {
        name: '',
        username: 't.me/',
        phoneNumber: '+998',
        region: '',
        errors: {},
    };

    function formReducer(state, action) {
        switch (action.type) {
            case 'field':
                return {
                    ...state,
                    [action.fieldName]: action.payload,
                };
            case 'setErrors':
                return {
                    ...state,
                    errors: action.payload,
                };
            default:
                return state;
        }
    }

    const [state, localDispatch] = useReducer(formReducer, initialState);

    const validateForm = () => {
        const errors = {};
        if (!state.name.trim()) errors.name = t('registerWriteName');
        if (Number.isNaN(Number(state.phoneNumber.slice(1))) || state.phoneNumber.length < 13) errors.phoneNumber = t('chooseValidPhone');
        if (!birthday) errors.birthday = t('chooseBirthdayErr');
        if (!state.region.trim()) errors.region = t('chooseRegionErr');

        localDispatch({ type: 'setErrors', payload: errors });
        return Object.keys(errors).length === 0;
    };


    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (!value.startsWith('+998')) {
            localDispatch({ type: 'field', fieldName: 'phoneNumber', payload: "+998" });
        } else {
            localDispatch({ type: 'field', fieldName: 'phoneNumber', payload: value });
        }
    };




    const handleChange = useCallback(
        (e) => {
            const {name, value} = e.target
            localDispatch({type: 'field', fieldName: name, payload: value})
        },
        [],
    );


    const  handleMainBtn = async () => {
        if (validateForm()) {
            dispatch(setUser({name: state.name, username: state.username, birthday,  phoneNumber: state.phoneNumber, region: state.region, entityType: 'person'}))
            window.Telegram.WebApp.MainButton.showProgress((leave = false) => {})

            const res = await updateClient({
                registered_name: state.name,
                username: state.username,
                birthday: birthday.getFullYear() + "-" + (birthday.getMonth()+1) + "-" + birthday.getDate()  ,
                phone_number: state.phoneNumber,
                region: state.region,
                language: i18n.language,
            })
            window.Telegram.WebApp.MainButton.hideProgress()
            if (window.Telegram.WebApp.initDataUnsafe.start_param) {
                f7.views.main.router.navigate(`/carcard/${window.Telegram.WebApp.initDataUnsafe.start_param}/`)
            } else {
                f7.views.main.router.navigate("/market")
            }

            console.log(res)
        }

    }
    /*useEffect(() => {
        localDispatch({ type: 'field', fieldName: 'username', payload: user?.username || ''});
        localDispatch({ type: 'field', fieldName: 'phoneNumber', payload: user?.phoneNumber});
    }, [])*/

    useEffect(() => {
            const user = window.Telegram.WebApp.initDataUnsafe.user;
            if (user) {
                let telegramId = user.id;
                console.log(user.username)
                localDispatch({ type: 'field', fieldName: 'username', payload: user.username });
            }}
        , [])


    useEffect(() => {
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = t('ready');

        window.Telegram.WebApp.BackButton.hide();
    }, []);


    useEffect(() => {
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);

        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.offEvent("mainButtonClicked", handleMainBtn)
        })
    }, [handleMainBtn])


    useEffect(() => {
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
            input.addEventListener('focus', () => {
                setTimeout(() => {
                    input.scrollIntoView({behavior: 'smooth'});
                }, 300);
            });
        });
    }, [handleMainBtn])



    return (
        <Page onPageAfterOut={() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        }}>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>{t('register')}</BlockTitle>
            <List strongIos dividersIos insetIos>
                <ListInput
                    maxlength={15}
                    name="name"
                    type="text"
                    value={state.name}
                    onChange={handleChange}
                    errorMessageForce={state.errors.name}
                    errorMessage={state.errors.name}
                    placeholder={t('registerName')}
                />
            </List>
            <BlockFooter>{t('registerWriteName')}</BlockFooter>


            <List strongIos dividersIos insetIos>
                <ListInput
                    errorMessageForce={state.errors.phoneNumber}
                    errorMessage={state.errors.phoneNumber}
                    name="phoneNumber"
                    type={"tel"}
                    inputmode={"numeric"}
                    placeholder="Номер телефона"
                    maxlength={13}
                    value={state.phoneNumber}
                    onChange={handlePhoneNumberChange}
                />
            </List>
            <BlockFooter>{t('registerWritePhone')}</BlockFooter>
            <List strongIos insetIos>
                <ListInput
                    type="datepicker"
                    placeholder={t("birthday")}
                    readonly
                    errorMessage={state.errors.birthday}
                    errorMessageForce={state.errors.birthday}
                    calendarParams={{
                        openIn: 'customModal',
                        header: true,
                        footer: true,
                        yearPickerMin: 1950,
                        yearPickerMax: 2010,
                        locale: i18n.language,
                        toolbarCloseText: t('ready'),
                        touchMove: false,
                        on: {
                            open(cal) {
                                cal.setYearMonth(1990, 1, 0);
                                cal.update();
                            },
                            change(calendar, value) {
                                setBirthday(value[0]);
                            }
                        }
                    }}
                />
            </List>

            <BlockFooter>{t('registerPickYourDate')}</BlockFooter>

            <List strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          errorMessageForce={state.errors.region}
                          errorMessage={state.errors.region}
                          title={t('registerRegion')}
                          smartSelect
                >
                    <select value={state.region} name="region" onChange={handleChange}
                    >
                        <option value='' disabled defaultChecked></option>
                        <option value="tashkent">{t('regions.tashkent')}</option>
                        <option value="tashkent_obl">{t('regions.tashkent_obl')}</option>
                        <option value="fergana">{t('regions.fergana')}</option>
                        <option value="andijan">{t('regions.andijan')}</option>
                        <option value="namangan">{t('regions.namangan')}</option>
                        <option value="bukhara">{t('regions.bukhara')}</option>
                        <option value="navoi">{t('regions.navoi')}</option>
                        <option value="khorezm">{t('regions.khorezm')}</option>
                        <option value="surkhandarya">{t('regions.surkhandarya')}</option>
                        <option value="kashkadarya">{t('regions.kashkadarya')}</option>
                        <option value="syrdarya">{t('regions.syrdarya')}</option>
                        <option value="jizzakh">{t('regions.jizzakh')}</option>
                        <option value="samarkand">{t('regions.samarkand')}</option>
                        <option value="karakalpakstan">{t('regions.karakalpakstan')}</option>
                    </select>
                </ListItem>
            </List>
            <BlockFooter style={{marginBottom: 0}}>{t('registerPickRegion')}</BlockFooter>
            {state.errors.region && state.region === "" && <BlockFooter style={{position: 'absolute',color:"#FF3B30"}}>{state.errors.region}</BlockFooter>}

            <div style={{marginBottom: 120}}/>
        </Page>
    );
}


export default Register;
