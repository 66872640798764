import {configureStore} from '@reduxjs/toolkit';

export const FAV_CAR = 'FAV_CAR';
export const REMOVE_CAR = 'REMOVE_CAR';
export const SET_USER = 'SET_USER';
export const SET_FILTER = 'SET_FILTER';


export const favCar = (car) => ({
    type: FAV_CAR,
    payload: { ...car}
});

export const removeCar = (id) => ({
    type: REMOVE_CAR,
    payload: id
});

export const setUser = (user) => ({
    type: SET_USER,
    payload: user
});

export const setFilter = (filter) => ({
    type: SET_FILTER,
    payload: filter
})

const initialState = {
    cars: [],
    user: null,
    filter: {},
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case FAV_CAR:
                return {
                    ...state,
                    cars: [...state.cars, action.payload]
                };
        case REMOVE_CAR:
            return {
                ...state,
                cars: state.cars.filter(car => car.id !== action.payload)
            }
        case SET_USER:
            return {
                ...state,
                user: action.payload,
            }

        case SET_FILTER:
            return {
                ...state,
                filter: action.payload,
            }


        default:
            return state;
    }
}


const store = configureStore({
    reducer: rootReducer,
});

export default store;