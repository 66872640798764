import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next)
    .init({
        fallbackLng: "uz",
        lng: "uz",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            ru: {
                translation: {
                    ready: 'Готово',
                    interfaceLang: 'Язык интерфейса',
                    welcome: 'Добро пожаловать!👋',
                    welcomeDescription: ' - маркетплейс по покупке и продаже автомобилей в Узбекистане 🇺🇿',
                    chooseLang: '🇷🇺 Выберите язык',
                    continueBtn: 'ПРОДОЛЖИТЬ',
                    descriptionQuality: 'Широкий выбор авто',
                    descriptionSafe: 'Полная ясность',
                    descriptionCost: 'Быстрая сделка',
                    qualityDesc: 'От свежих моделей до проверенных б/у автомобилей. Найди идеальный вариант за пару кликов',
                    safeDesc:'Вся информация о машине и продавце на виду, без лишних вопросов и скрытых условий',
                    costDesc: 'Быстрое размещение объявления и удобные инструменты для быстрой сделки без лишних усилий',


                    register: "Регистрация",
                    registerName: 'Ваше имя',
                    registerWriteName: 'Укажите ваше имя',
                    registerWritePhone: 'Укажите номер телефона для связи',
                    registerPickDate: 'Выбрать дату',
                    registerPickYourDate: 'Укажите дату вашего рождения',
                    registerRegion: 'Регион',
                    registerPickRegion: 'Укажите регион вашего проживания',
                    registerWriteUsername: 'Ваш username в телеграмме',
                    goToCarFormBtn: 'Перейти к добавлению машины',
                    chooseBirthdayErr: 'Укажите свой день рождения',
                    chooseRegionErr: 'Укажите регион',
                    chooseUsernameErr: 'Укажите username',
                    chooseValidPhone: 'Укажите корректный номер телефона',

                    popular: "Популярные",
                    recomend: 'Рекомендации',
                    search: 'Поиск',
                    filter: 'Фильтр',
                    noFilter: 'Без фильтра',
                    cheapFirst: 'Сначала дешевые',
                    expensiveFirst: 'Сначала дорогие',
                    outage: 'Выпуск',
                    byMark: 'По марке',
                    lan: 'Язык',
                    call: 'Позвонить',
                    torg: 'Снизить цену',
                    torgDesc: 'Хотите снизить цену? Мы отправим ваши контакты продавцу для лучших условий. Согласны?',
                    contact: 'Продавец',
                    goToDirect: 'Написать продавцу',
                    year: 'Год',
                    costRange: 'Диапазон цены',
                    apply: 'Применить',
                    reset: 'Сброс',
                    nothingFound: 'Ничего не найдено',
                    som: 'сум',
                    dogovor: 'Договорная',

                    birthday: 'Дата рождения',
                    publish: "Разместить",
                    cars: 'Машины',
                    fav: 'Избранное',



                    yetEmpty: 'Вы ещё не выбрали автомобиль по своему вкусу',
                    carAddition: 'Добавление машины',
                    brand: 'Марка',
                    model: 'Модель',
                    position: 'Позиция',
                    provideCarData: 'Укажите комплектацию вашего автомобиля',
                    color: 'Цвет',
                    carColor: 'Цвет вашего автомобиля',
                    transmission: 'Коробка передач',
                    chooseYear: 'Указать год',
                    pickYear: 'Выберите год производста вашего автомобиля',
                    cost: 'Цена',
                    mileage: 'Пробег',
                    description: 'Дополнительная информация',
                    recEmpty: 'Подбираем автомобили, соответствующие вашим интересам',

                    top: 'Топ',
                    cheapest: 'Низкая цена',
                    newCar: 'Новое',

                    saveChangesBtn: 'Сохранить изменения',
                    options:'Опции',
                    optics: 'Оптика',
                    saletype: 'Тип продажи',
                    fueltype: 'Тип топлива',



                    regions: {
                        tashkent: "г. Ташкент",
                        tashkent_obl: "Ташкентская область",
                        fergana: "Ферганская область",
                        samarkand: "Самаркандская область",
                        bukhara: "Бухарская область",
                        andijan: "Андижанская область",
                        namangan: "Наманганская область",
                        navoi: "Навоийская область",
                        khorezm: "Хорезмская область",
                        surkhandarya: "Сурхандарьинская область",
                        kashkadarya: "Кашкадарьинская область",
                        syrdarya: "Сырдарьинская область",
                        jizzakh: "Джизакская область",
                        karakalpakstan: "Каракалпакстан",
                    },












                },
            },
            uz: {
                translation: {
                    birthday: 'Tug’ilgan sana',
                    priceSum: 'Narx somda:',
                    interfaceLang: 'Interface tili',
                    welcome: 'Xush kelibsiz! 👋',
                    welcomeDescription: ' - O’zbekistonda avtomobillarni sotib olish va sotish uchun online bozor 🇺🇿',
                    chooseLang: '🇺🇿 Tilni tanlang',
                    continueBtn: 'DAVOM ETISH',
                    descriptionQuality: 'Keng avtomobil tanlovi',
                    descriptionSafe: `To’liq aniqlik`,
                    descriptionCost: 'Tezda sotish',
                    qualityDesc: 'Yangi modellardan sinovdan o’tgan ishlatilgan mashinalargacha. Bir necha qadamda o’zingizga mos avtomobilni toping',
                    safeDesc:'Avtomobil va sotuvchi haqida barcha ma’lumotlar ochiq, ortiqcha savollar va yashirin shartlarsiz',
                    costDesc: 'E’lonni tez joylashtirish va ortiqcha harakatsiz tez sotish uchun qulay vositalar',
                    reset: 'Bekor kilish',
                    register: "Roʻyxatdan oʻtish",
                    registerName: 'Ismingiz',
                    registerWriteName: 'Ismingizni kiriting',
                    registerWritePhone: 'Aloqa uchun telefon raqamingizni kiriting',
                    registerPickDate: 'Sana tanlang',
                    registerPickYourDate: `Tug’ilgan sanangizni kiriting`,
                    registerRegion: 'Hududingiz',
                    registerPickRegion: 'Yashash hududingizni kiriting',
                    registerWriteUsername: 'Telegramdagi foydalanuvchi ismingiz(@username)',
                    goToCarFormBtn: `Mashinani qo'shishga o'ting`,
                    chooseBirthdayErr: `Tug'ilgan kuningizni kiriting`,
                    chooseRegionErr: 'Hududingizni belgilang',
                    chooseUsernameErr: 'Telegramdagi foydalanuvchi ismingiz(@username) belgilang',
                    chooseValidPhone: `Iltimos, to'g'ri telefon raqamingiznii kiriting`,

                    deleteText: 'Mashinani ochirish',
                    deleteDescription: `Iltimos, o'chirish sababini yozing`,
                    reason: 'Sabab',

                    recEmpty: 'Sizning qiziqishlaringizga mos avtomobillarni tanlayapmiz',
                    yetEmpty: 'Siz hali o’zingizga yoqadigan avtomobilni tanlamadingiz',
                    ready: 'Yakunlash',
                    carAddition: `Mashinani qo'shish`,
                    brand: 'Marka',
                    model: 'Model',
                    position: 'Pozitsiya',
                    provideCarData: 'Mashinangizni komplektatsiyasini belgilang',
                    color: 'Rang',
                    carColor: 'Mashinangizni rangi',
                    transmission: 'Uzatish qutisi',
                    chooseYear: 'Yilini kiriting',
                    img:'Surat',
                    pickYear: 'Mashinangizni ishlab chiqarilgan yilini tanlang',
                    cost: 'Narx',
                    mileage: 'Probeg',
                    description: `Qo'shimcha ma'lumot`,
                    writeDescription: `Mashinangizni qo'shimcha ma'lumotini kiriting (50-200 so'z)`,
                    pickImage: `Mashinangizni rasmini qo'shing`,
                    canTrade: 'Kelishish imkoniyati',
                    canTradeDesc: 'Xaridorlarga narxni kelishib olish imkonini beradi',
                    provideCarDataErr: "Iltimos, mashinaning to'liq komplektatsiyasini ko'rsating",
                    pickColorErr: 'Rangni belgilang',
                    pickTransmissionErr: 'Uzatish qutisini belgilang',
                    pickYearErr: 'Yilni kiriting',
                    pickCostErr: 'Toʻgʻri narxni kiriting',
                    pickMileageErr: 'Probegni kiriting',
                    pickDescriptionErr: `Qo'shimcha ma'lumotni kiriting`,
                    pickImageErr: `Mashinangizni rasmini qo'shing`,

                    myCars: 'Mening mashinalarim',
                    myData: `Mening ma'lumotlarim`,
                    popular: "Mashhur",
                    recomend: "Tavsiya",
                    search: "Qidirish",
                    filter: "Filtr",
                    noFilter: "Filtrsiz",
                    cheapFirst: "Avval arzonlar",
                    expensiveFirst: "Avval qimmatlar",
                    outage: "Yil",
                    byMark: "Marka bo'yicha",
                    lan: "Til",
                    call: "Telefon qilish",
                    torg: "Narxni tushirish",
                    torgDesc: 'Narxni tushirishni xohlaysizmi? Sizning kontaktlaringizni sotuvchiga yuboramiz, yaxshiroq taklif beradi. Rozimisiz?',
                    contact: "Sotuvchi",
                    goToDirect: "Sotuvchiga yozish",
                    year: "Yil",
                    costRange: "Narx oralig'i",
                    apply: "Qo'llash",
                    nothingFound: "Hech narsa topilmadi",
                    som: "so'm",
                    dogovor: "Kelishuv bor",
                    publish: "E'lon qo'yish",
                    cars: "Avtomobillar",
                    fav: "Sevimlilar",

                    top: 'Top',
                    cheapest: 'Arzon narx',
                    newCar: 'Yangi',


                    personalData: `Shaxsiy ma'lumotlarim`,
                    addCarBtn: `Mashinangizni qo'shing`,
                    deleteCar: `Mashinani o'chirish`,
                    saveChangesBtn: `O'zgarishlarni saqlash`,
                    options:'Opsiyalar',
                    optics: 'Optika',
                    saletype: 'Sotuv yoli',
                    fueltype: 'Yoqilgi turi',

                    becomeLegalEntity: 'Yuridik shaxsga aylanish',
                    legalEntityForm: `Huquqiy yuridik maqomni ro'yxatdan o'tkazish`,
                    dealerName: 'avtosalonning nomi',
                    chooseDealerName: 'Avtosalonningizni nomini kiriting',
                    location: 'Joylashuv',
                    chooseLocation: 'Avtosalonningizni joylashuvi',
                    chooseLogo: 'Logotipingizni kiriting',
                    sendRequestBtn: 'Arizani yuboring',
                    pickLocationBtn: 'Bu joylashuvni belgilang',

                    regions: {
                        tashkent: "Toshkent shahri",
                        tashkent_obl: "Toshkent viloyati",
                        fergana: "Farg‘ona viloyati",
                        samarkand: "Samarqand viloyati",
                        bukhara: "Buxoro viloyati",
                        andijan: "Andijon viloyati",
                        namangan: "Namangan viloyati",
                        navoi: "Navoiy viloyati",
                        khorezm: "Xorazm viloyati",
                        surkhandarya: "Surxondaryo viloyati",
                        kashkadarya: "Qashqadaryo viloyati",
                        syrdarya: "Sirdaryo viloyati",
                        jizzakh: "Jizzax viloyati",
                        karakalpakstan: "Qoraqalpog‘iston",
                    },
                },
            },
        },
    });

export default i18next;