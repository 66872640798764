
const getRatings = (t) => ({
    top: {
        text: t('top'),
        color: 'rgba(203,193,9,0.9)',
    },
    best_price: {
        text: t('cheapest'),
        color: 'rgba(133,154,234,0.9)',
    },
    new: {
        text: t('newCar'),
        color: 'rgba(112,197,84,0.9)',
    }
});

export default getRatings;
