import React, {useEffect, useRef, useState} from 'react';
import {
    App,
    Block,
    BlockTitle,
    Button,
    Card,
    CardContent, CardHeader, Icon,
    Link,
    Page,
    Searchbar,
    Segmented,
    Range,
    Tab,
    Tabs, Toolbar,
    f7, Popover, List, ListItem, f7ready, ListInput, Popup, View, NavRight, Navbar, BlockFooter
} from "framework7-react";
import wallet from '../assets/icons/wallet.svg'
import filter from '../assets/icons/filter.svg'


import sellz_logo from "../assets/sellz_logo.jpg";
import support from "../assets/icons/support.svg";
import HeartButton from "../Components/HeartButton";
import {useSelector} from "react-redux";
import share from "../assets/icons/share.svg";
import {fetchCars, fetchNext, fetchQueryCars, getRecommendedCars} from "../api/cars";
import axios from "axios";
import {Login, updateClient} from "../api/user";
import {useTranslation} from "react-i18next";
import ratings from "../Enums/ratings";
import getRatings from "../Enums/ratings";


const Marketplace = (props) => {
    f7ready(() => {

    })

    const {i18n, t} = useTranslation();

    const [activeStrongButton, setActiveStrongButton] = useState(0);

    const regions = t('regions', {returnObjects: true});
    const [brandOptions, setBrandOptions] = useState(JSON.parse(localStorage.getItem('brandOptions')));
    const [transmissionOptions, setTransmissionOptions] = useState(JSON.parse(localStorage.getItem('transmissionOptions')));

    const [brand, setBrand] = useState('');
    const [priceMin, setPriceMin] = useState(0);
    const [priceMax, setPriceMax] = useState(0);
    const [costSort, setCostSort] = useState('')

    const onPriceChange = (values) => {
        setPriceMin(values[0]);
        setPriceMax(values[1]);
    };

    const [lang, setLang] = useState(i18n.language);

    const [curCars, setCurCars] = useState( [])
    const [recommendedCars, setRecommendedCars] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    const [nextURL, setNextURL] = useState('');
    const observerRef = useRef(null);
    const lastCarRef = useRef(null);

    useEffect(() => {
        if (props?.query) {
            fetchQCars(props.query)
        } else if (activeStrongButton === 0) {
            getCars()
        } else {
            getRecommendCars()
        }
    }, [activeStrongButton])

    useEffect(() => {
        window.Telegram.WebApp.MainButton.isVisible = false;
    }, [])


    const getCars = async () => {
        const res = await fetchCars();
        setCurCars(res.results)
        setNextURL(res.next)
    }

    const getRecommendCars = async () => {
        const res = await getRecommendedCars()
        setRecommendedCars(res.results)
        console.log(recommendedCars)
        setNextURL(res.next)
    }

    const changeLang = (selectedLanguage) => {
        setLang(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
        updateClient({
            language: i18n.language,
        })
    }

    useEffect(() => {
        fetchQCars();
    }, [costSort]);

    const fetchQCars = async (userQuery = '') => {
        let query = '?';
        if (costSort === 'cheap') {
            query += 'order_by=price&';
        } else if (costSort === 'expensive') {
            query += 'order_by=-price&';
        }
        query += userQuery;
        console.log('Fetching with query:', query);
        const res = await fetchQueryCars(query);
        setCurCars(res.results);
        setNextURL(res.next);
    };



    const fetchNextCars = async (next) => {
        const res = await fetchNext(next)
        setCurCars((prevState)=> [...prevState, ...res.results])
        setNextURL(res.next)
        console.log(curCars)
    }





    const handleSupport = () => {
        /*const urlLink = `https://t.me/zidreime`
        window.Telegram.WebApp.openTelegramLink(urlLink)*/
    }
    const handleShare = (id) => {
        const urlLink = `https://t.me/share/url?url=https://t.me/selluzautobot/selluzauto?startapp=${id}`
        window.Telegram.WebApp.openTelegramLink(urlLink)
    }

    useEffect(() => {
        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && nextURL) {
                    fetchNextCars(nextURL);
                }
            },
            { threshold: 1 }
        );

        if (lastCarRef.current) {
            observerRef.current.observe(lastCarRef.current);
        }

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
    }, [curCars, nextURL]);







    return (
        <Page className="toola">
            <div style={{marginTop: 15}} className="custom-searchbar-container">
                <div onClick={handleSupport}>
                    <img
                        width={30}
                        src={support}
                        alt="Chat Logo"
                        className="chat-logo"
                    />
                </div>

                <div className="searchbar-wrapper">
                    <Searchbar
                        style={{backgroundColor: 'transparent', borderRadius: 10}}
                        className="custom-searchbar"
                        placeholder={t('search')}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        searchContainer=".search-list"
                        searchIn=".item-title"
                        outline={false}
                        clearButton={false}
                        disableButton={false}
                        customSearch={true}
                    />
                </div>
                <Link popoverOpen=".language-popover">
                    <div className="right-icons">
                        <span className="flag-icon">{lang === 'uz' ? '🇺🇿' : '🇷🇺'}</span>
                    </div>
                </Link>
            </div>
            <div className='flex justify-end mb-0'>
            <div className='mt-[15px] ml-auto mr-[44px] flex flex-row gap-x-2'>
                <Link className='flex flex-row items-center justify-center bg-white p-1 rounded-xl' popoverOpen=".filter-popover">
                    <img
                        src={wallet}
                        width={20}
                    />
                    <span className='text-black font-bold ml-2'>{t('cost')}</span>
                </Link>
                <Link className='bg-white p-1 rounded-xl' onClick={() => {f7.views.main.router.navigate('/filter')}}>
                    <img
                        src={filter}
                        width={20}
                    />
                    <span className='text-black font-bold ml-2'>{t('filter')}</span>
                </Link>
            </div>
            </div>
                <Segmented className='my-segm' round style={{marginLeft: 20, marginRight: 20}} strong tag="p">
                    <Button round active={activeStrongButton === 0} onClick={() => setActiveStrongButton(0)}>
                        {t('popular')}
                    </Button>
                    <Button round active={activeStrongButton === 1} onClick={() => setActiveStrongButton(1)}>
                        {t('recomend')}
                    </Button>
                </Segmented>

            <Block style={{marginTop: '20px'}} className="grid grid-cols-2 gap-y-2.5 gap-x-2.5 p-0">

                {activeStrongButton === 0 ? curCars.length !== 0 ? (curCars.map((car, index) => {
                    const isLastCar = index === curCars.length - 1;
                    return (
                        <div>

                        <div
                            ref={isLastCar ? lastCarRef : null}
                            key={car.id}
                            onClick={() => {f7.views.main.router.navigate(`/carcard/${car.id}/`)}}
                            className=" relative bg-color-white rounded-xl flex-grow w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0"
                        >
                            <div onClick={() => handleShare(car.id)}  className="absolute shadow-lg overflow-hidden top-1 right-8 flex w-6 h-6 bg-white items-center justify-center rounded-[10px]">
                                <img alt="share" width={15} src={share}/>
                            </div>
                                <HeartButton combStyle='absolute shadow overflow-hidden top-1 right-1' car={car} isFav={car.is_favorite}/>
                            <div
                                style={{
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'left center',
                                    height: 115,
                                    backgroundImage: `url(https://dev.selluzauto.uz/${car?.images[0]})`,
                                }}
                                className="rounded-t-xl w-full"
                            >
                                {car.offer_type && (
                                    <div
                                        style={{backgroundColor: `${getRatings(t)[car?.offer_type].color}`}}
                                        className="whitespace-nowrap px-3 w-fit p-1 w-16 break-normal flex items-center justify-center text-[10px] rounded-tl-xl rounded-tr-md rounded-br-md text-white font-bold"
                                    >
                                        <span className="">{getRatings(t)[car?.offer_type].text}</span>
                                    </div>)}</div>
                            <BlockTitle style={{margin:0}} className="pl-2 pt-2">{car.brand} {car.model}</BlockTitle>
                            <p className="pl-2 text-sm">🚗 {t('outage')}: {car.year}</p>
                            <p className="mt-0 pt-0 -mt-[5px] pl-2 text-sm">🛞 {"  "} {t('mileage')}: {car.mileage} km</p>
                            <div className='relative flex'>
                                <p className='pl-2 text-[9px] text-gray-500'>{car.created_at} <br/> {t(`regions.${car?.region}`)}</p>
                                <p className='absolute text-md font-bold right-2'>{(Number.parseInt(car.price_usd).toLocaleString('en-US').replace(/,/g, ' '))} $</p>
                            </div>
                        </div>
                            {index === curCars.length - 1 ? <div style={{marginBottom: 100}}></div> : ''}
                        </div>)
                })) : (<p>{t('nothingFound')}</p>) : recommendedCars.length !== 0 ? (recommendedCars.map((car, index) => {
                    const isLastCar = index === curCars.length - 1;
                    return (
                        <div>

                            <div
                                ref={isLastCar ? lastCarRef : null}
                                key={car.id}
                                onClick={() => {f7.views.main.router.navigate(`/carcard/${car.id}/`)}}
                                className=" relative bg-color-white rounded-xl flex-grow w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0"
                            >
                                <div onClick={() => handleShare(car.id)}  className="absolute shadow-lg overflow-hidden top-1 right-8 flex w-6 h-6 bg-white items-center justify-center rounded-[10px]">
                                    <img alt="share" width={15} src={share}/>
                                </div>
                                <HeartButton combStyle='absolute shadow overflow-hidden top-1 right-1' car={car} isFav={car.is_favorite}/>
                                <div
                                    style={{
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'left center',
                                        height: 115,
                                        backgroundImage: `url(https://selluzauto.uz/${car?.images[0]})`,
                                    }}
                                    className="rounded-t-xl w-full"
                                >
                                    {car.offer_type && (
                                        <div
                                            style={{backgroundColor: `${getRatings(t)[car?.offer_type].color}`}}
                                            className="whitespace-nowrap px-3 w-fit p-1 w-16 break-normal flex items-center justify-center text-[10px] rounded-tl-xl rounded-tr-md rounded-br-md text-white font-bold"
                                        >
                                            <span className="">{getRatings(t)[car?.offer_type].text}</span>
                                        </div>)}</div>
                                <BlockTitle style={{margin:0}} className="pl-2 pt-2">{car.model}</BlockTitle>
                                <p className="pl-2 text-sm">🚗 {t('outage')}: {car.year}</p>
                                <p className="mt-0 pt-0 -mt-[5px] pl-2 text-sm">🛞 {"  "} {t('mileage')}: {car.mileage} km</p>
                                <div className='relative flex'>
                                    <p className='pl-2 text-[9px] text-gray-500'>{car.created_at} <br/> {t(`regions.${car?.region}`)}</p>
                                    <p className='absolute text-md font-bold top-1 right-2'>{(Number.parseInt(car.price_usd).toLocaleString('en-US').replace(/,/g, ' '))} $</p>
                                </div>
                            </div>
                            {index === curCars.length - 1 ? <div style={{marginBottom: 100}}></div> : ''}
                        </div>)
                })) : <BlockFooter style={{color: 'black'}}>{t('recEmpty')}</BlockFooter>}
            </Block>


            <div style={{position: 'fixed', paddingBottom:80}} className="toolbar tabbar-icons toolbar-bottom">
                <div style={{marginBottom: 30}} className="toolbar-inner">
                    <Link tabLinkActive tabLink="#tab-1" >
                        <Icon f7="car_fill"></Icon>
                        <span className="tabbar-label">{t('cars')}</span>
                    </Link>
                    <Link onClick={() => {window.open('https://t.me/selluzpartnerbot?start=')}} tabLink="#tab-2">
                        <Icon f7={"plus_fill"}/>
                        <span className="tabbar-label">{t('publish')}</span>
                    </Link>
                    <Link onClick={() => {f7.views.main.router.navigate("/favourites"); window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");}}   tabLink="#tab-3">
                        <Icon f7={"heart_fill"}/>
                        <span className="tabbar-label">{t('fav')}</span>
                    </Link>
                </div>
            </div>
            <Popover arrow  className="language-popover">
                <h1 className="pl-4 pt-1 font-bold">{t('lan')}</h1>
                <List inset dividersIos>
                <ListItem onChange={(e) => {changeLang(e.target.value); f7.popover.close();}} checked={lang === "ru"} radio radioIcon="end" title="Русский 🇷🇺" value="ru" name="demo-radio-end" />
                <ListItem  onChange={(e) => {changeLang(e.target.value); f7.popover.close();}} checked={lang === "uz"} radio radioIcon="end" title="O'zbekcha 🇺🇿" value="uz" name="demo-radio-end" />
                </List>
            </Popover>
            <Popover arrow  className="filter-popover">
                <List inset dividersIos>
                    <ListItem onChange={(e) => {setCostSort(e.target.value); fetchQCars(); f7.popover.close();}} checked={costSort === ""} radio radioIcon="end" title={t('noFilter')} value="" name="demo-radio-end1" />
                    <ListItem onChange={(e) => {setCostSort(e.target.value); fetchQCars(); f7.popover.close();}} checked={costSort === "cheap"} radio radioIcon="end" title={t('cheapFirst')} value="cheap" name="demo-radio-end1" />
                    <ListItem  onChange={(e) => {setCostSort(e.target.value); fetchQCars(); f7.popover.close();}} checked={costSort === "expensive"} radio radioIcon="end" title={t('expensiveFirst')} value="expensive" name="demo-radio-end1" />
                </List>
            </Popover>


        </Page>
    );
};

export default Marketplace;
