import React, {useEffect} from 'react';
import {f7, Page, Preloader} from "framework7-react";
import {getClient, getHandbook, Login} from "../api/user";
import axios from "axios";
import {useTranslation} from "react-i18next";

const Loader = () => {
    window.Telegram.WebApp.expand();
    const {i18n, t} = useTranslation();
    const fetchHandbooks = async () => {
        const brandRes = await getHandbook('brand');
        localStorage.setItem('brandOptions', JSON.stringify(brandRes));

        const colorRes = await getHandbook('color');
        console.log(colorRes)
        localStorage.setItem('colorOptions', JSON.stringify(colorRes));


        const transmissionRes = await getHandbook('transmissiontype');
        console.log(transmissionRes)
        localStorage.setItem('transmissionOptions', JSON.stringify(transmissionRes));
    }

    useEffect(() => {
        async function login() {
            try {
                const res = await Login({init_data: window.Telegram.WebApp.initData})
                if (res.status === 'success') {
                    localStorage.setItem('token', res.access);
                    localStorage.setItem('refresh', res.refresh);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${res.access}`
                    await fetchHandbooks();
                }
            } catch (e) {
                console.log(e)
            }
        }
        login().then(() => {
            getClient().then(res => {
                console.log(res)
                if (res.data.fully_registered) {
                    console.log(res)
                    i18n.changeLanguage(res.data.language);
                    if (window.Telegram.WebApp.initDataUnsafe.start_param) {
                        f7.views.main.router.navigate(`/carcard/${window.Telegram.WebApp.initDataUnsafe.start_param}/`)
                        return;
                    }
                    f7.views.main.router.navigate('/market')
                } else {
                    f7.views.main.router.navigate('/startup')
                }
            })
        })
    }, [])
    return (
        <Page>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                <Preloader size={20} color="#4E80EE"/>
            </div>
        </Page>
    );
};

export default Loader;
