import React, {useState} from 'react';
import heart_filled from "../assets/icons/heart_filled.svg";
import heart from "../assets/icons/heart.svg";
import {useDispatch} from "react-redux";
import {favCar, removeCar} from "../store/store";
import {addFavorite, removeFavorite} from "../api/cars";

const HeartButton = ({car, isFav, combStyle}) => {
    const [isFilled, setIsFilled] = useState(isFav);
    const dispatch = useDispatch();

    const handleChange = async (e) => {
        e.stopPropagation()
        if (!isFilled) {
            const res = await addFavorite({car: car.id})
            console.log(car)
            console.log(isFav)
            console.log(res)
        } else {
            const res = await removeFavorite(car.id)
            console.log(res)
        }
        setIsFilled(!isFilled)

    }
    return (
        <div onClick={(e) => handleChange(e)} className={`flex w-6 h-6 bg-white items-center justify-center rounded-[10px] ${combStyle}`}>
            <img alt="heart" src={isFilled ? heart_filled : heart}/>
        </div>
    );
};

export default HeartButton;