import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Framework7 from 'framework7/lite-bundle';
import  Framework7React  from 'framework7-react';
import 'framework7/css/bundle';
import store from "./store/store";
import {Provider} from "react-redux";
import eruda from "eruda";
import './i18n';


Framework7.use(Framework7React);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
    <App />
    </Provider>
);

