import axios from "axios";

export const getCarByID = async (id) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.get(`https://dev.selluzauto.uz/api/marketplace/car/${id}/`);
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const fetchCars = async () => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.get('https://dev.selluzauto.uz/api/marketplace/car/');
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const fetchQueryCars = async (query) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.get(`https://dev.selluzauto.uz/api/marketplace/car/${query}`);
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const fetchNext = async (next) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.get(next);
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const getRecommendedCars = async () => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.get(`https://dev.selluzauto.uz/api/marketplace/recommended/`);
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const removeFavorite = async (id) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.delete(`https://dev.selluzauto.uz/api/marketplace/favorites/${id}/`);
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const addFavorite = async (data) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.post(`https://dev.selluzauto.uz/api/marketplace/favorites/`, data);
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const getFavorites = async () => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.get('https://dev.selluzauto.uz/api/marketplace/favorites?per_page=8')
        return res.data
    } catch (e) {
        console.log(e)
    }
}