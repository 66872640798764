import React, {useCallback, useEffect, useRef, useState} from 'react';
import {BlockFooter, BlockTitle, Button, f7, Page, PhotoBrowser} from "framework7-react";
import sellz_logo from "../assets/sellz_logo.jpg";
import HeartButton from "../Components/HeartButton";
import {useSelector} from "react-redux";
import share from "../assets/icons/share.svg";
import eyes from "../assets/icons/eyes.svg";
import face from "../assets/face.png";
import {getCarByID} from "../api/cars";
import {useTranslation} from "react-i18next";
import {doBargain} from "../api/user";

const CarCard = ({ f7route }) => {
    const {i18n, t} = useTranslation();
    f7.views.main.history.pop();
    const { id } = f7route.params;

    const standalone = useRef(null);

    const [colors, setColors] = useState(JSON.parse(localStorage.getItem('colorOptions')))
    const [thisCar, setThisCar] = useState(id)
    const [phoneNumber, setPhoneNumber] = useState("");
    const [infoData, setInfoData] = useState([])


    const reqContact = () => {
        window.Telegram.WebApp.showConfirm( t('torgDesc'), async (yes) => {
            if (yes) {
                console.log(id)
                const res = await doBargain({car_id: id});
                console.log(res)
            }
        })
    }

    const handleShare = () => {
        const urlLink = `https://t.me/share/url?url=https://t.me/selluzautobot/selluzauto?startapp=${id}`
        window.Telegram.WebApp.openTelegramLink(urlLink)
    }
    const dialPhone = () => {
        window.open(`tel:${thisCar?.partner_id?.phone_number}`);
    }

    useEffect(() => {
        const fetchCar = async () => {
            const data = await getCarByID(id);
            setThisCar(data)
            console.log(data)
            setInfoData([
                (data?.options?.length ? {title: t('options'), value: `${data?.options.map(item => ' ' + (i18n.language === 'ru' ? item.name_ru : item.name_uz))}`} : ''),
                (data?.optics?.length ? {title: t('optics'), value: `${data?.optics.map(item => ' ' + (i18n.language === 'ru' ? item.name_ru : item.name_uz))}`} : ''),
            ])
        }

        fetchCar()
    }, [])

    const openChat =() =>  {
            const urlLink = `https://t.me/${thisCar?.partner_id?.username}`
            window.Telegram.WebApp.openTelegramLink(urlLink)
        };



    useEffect(() => {
        const handleBackBtn = () => {
            f7.views.main.router.navigate("/market")
        }
        window.Telegram.WebApp.MainButton.color = '#4E80EE';
        window.Telegram.WebApp.MainButton.text = t('goToDirect')
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);
        window.Telegram.WebApp.onEvent('mainButtonClicked', openChat);

        return (() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', openChat);
        })

    }, [thisCar])

    const getSvgDataUrl = (colorCode) => {
        const svg = `
    <svg width="24" height="24" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="12" fill="${colorCode}" stroke="black" stroke-width="1"/>
    </svg>
  `;
        const encodedSvg = btoa(svg.trim());
        return `data:image/svg+xml;base64,${encodedSvg}`;
    };
    return (
        <Page onPageAfterOut={() => {
            standalone.current.close()
            window.Telegram.WebApp.BackButton.hide();
            window.Telegram.WebApp.MainButton.isVisible = false;
            window.Telegram.WebApp.offEvent('mainButtonClicked', openChat);
        }}>
            <PhotoBrowser photos={thisCar?.images?.map(image => `https://dev.selluzauto.uz/${image}`)} thumbs={thisCar?.images?.map(image => `https://selluzauto.uz/${image}`)} ref={standalone} />
            <div style={{ height: '100vh', overflowY: 'auto', position: 'relative' }}>
                <div onClick={() => {standalone.current.open()}}>
                    <swiper-container style={{zIndex: 100,}} pagination className="demo-swiper-multiple" space-between="0">
                        {thisCar?.images?.map(image => (
                            <swiper-slide>
                                <div className="cards-ctn" style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    backgroundImage: `url(https://dev.selluzauto.uz/${image})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}>
                                </div>
                            </swiper-slide>
                        ))}
                    </swiper-container>
                </div>
                <div style={{zIndex: 999999, position: 'absolute', display: 'flex', alignItems: 'center',justifyContent: 'center', marginRight: 0, height: '35px',width: "35px"}} className={`flex w-6 h-6 bg-white items-center justify-center rounded-[10px] absolute right-12 w-[35px] h-[35px] top-2 shadow-lg overflow-hidden`} onClick={handleShare}>
                    <img width={20} src={share}/>
                </div>
                <div style={{zIndex: 999999, position: 'absolute', display: 'flex', alignItems: 'center',justifyContent: 'center', marginRight: 0, height: '20px',width: 'auto',}} className={`flex w-10 h-6 bg-white items-center justify-center rounded-xl p-1 px-2 absolute left-2 w-[35px] h-[35px] top-[170px] shadow-lg overflow-hidden`} onClick={handleShare}>
                    <p className='flex flex-column gap-1 text-[#007AFF]'> <img width={20} src={eyes}/> {thisCar?.views}</p>
                </div>
                <HeartButton combStyle={`z-[999999] absolute shadow-xl overflow-hidden right-2 w-[35px] h-[35px] top-2`} car={thisCar} isFav={thisCar?.is_favorite}/>

            <div className='py-1' style={{backgroundColor: 'white', borderRadius: '0px 0px 15px 15px'}}>
                    <BlockTitle style={{marginTop: 15, fontSize: 20,lineHeight: 1.2, fontWeight: 'bold' }}>{thisCar?.brand_id?.name} {thisCar?.model_id?.name}</BlockTitle>
                    <BlockTitle className='flex justify-between items-center' style={{fontSize: 15,lineHeight: 1.2, marginTop: 0 }}>
                        <div className='flex gap-2'>
                            {(Number.parseInt(thisCar?.price_usd).toLocaleString('en-US').replace(/,/g, ' '))} $
                            <span style={{ color: 'gray'}}>{(Number.parseInt(thisCar?.price_uzs).toLocaleString('en-US').replace(/,/g, ' '))} {t('som')}</span>
                        </div>
                        {thisCar?.is_bargain && <span className='text-sm font-bold' style={{color: '#5ad58b'}}>{t('dogovor')}</span>}
                    </BlockTitle>
                {thisCar?.sale_types?.length !== 0  && <BlockTitle className='text-[#007AFF]' style={{marginTop: 0, fontSize: 15,lineHeight: 1.2,color: '#007AFF'  }}>{t('saletype')}: {thisCar?.sale_types?.length !== 0 ? <span className='font-light'>{thisCar?.sale_types?.map((item, index) => (' ' + (i18n.language === 'ru' ? item.name_ru : item.name_uz) + (index !== thisCar?.sale_types?.length - 1 ? ',' : '')))}</span> : <span className='font-light'>Не указано</span>}</BlockTitle>}
                </div>
            <div className='py-2' style={{marginTop: 10,backgroundColor: 'white', borderRadius: '15px 15px 15px 15px'}}>
                <BlockTitle style={{marginTop: 0, fontSize: 15,lineHeight: 1.2 }}>🚗  {t('outage')}: <span className='font-normal'>{thisCar?.year}</span></BlockTitle>
                <BlockTitle style={{marginTop: 0, marginRight: 8, fontSize: 15,lineHeight: 1.5, display: 'flex', alignItems:'center', whiteSpace: 'nowrap', marginLeft:18 }}><img style={{marginRight: 5}} width={18} src={getSvgDataUrl(colors?.find((item) => item.id === thisCar?.color_id?.id)?.code)}/> {t('color')}: {" "} <span className='font-normal pl-1'> {i18n.language === 'ru' ? " " + thisCar?.color_id?.name_ru : " " + thisCar?.color_id?.name_uz}</span></BlockTitle>
                <BlockTitle style={{marginTop: 0, fontSize: 15,lineHeight: 1.5 }}>🕹 {t('transmission')}: <span className='font-normal'>{i18n.language === 'ru' ? thisCar?.transmission_type_id?.name_ru : thisCar?.transmission_type_id?.name_uz}</span></BlockTitle>
                <BlockTitle style={{marginTop: 0, fontSize: 15,lineHeight: 1.5 }}>🛞 {t('mileage')}: <span className='font-normal'>{(Number.parseInt(thisCar?.mileage).toLocaleString('en-US').replace(/,/g, ' '))} KM</span></BlockTitle>

                <div className="options-wrap" style={{marginTop: 15}}>
                    {infoData.map((item) => {
                        if (!item) return;
                        return (<span className="inline-block px-2 py-1 my-1 border border-blue-400 text-black text-[2.9vw] rounded-xl whitespace-normal break-words w-auto"><span className='font-bold'>{item.title}:</span> {item.value}</span>)
                    })}
                </div>
                <BlockTitle style={{fontSize: 20, fontWeight: 'bold'}}>
                    {t('description')}
                </BlockTitle>

            <BlockFooter style={{
                color: 'black',
                fontSize: "3.2vw",
                overflow: 'visible',
                width: '90vw',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                marginBottom: 0,
            }}>{thisCar?.description}</BlockFooter>
                <div className='flex justify-between items-center mt-8 mb-28'>
                    <div className=' flex flex-row items-center'>
                        <img
                            className='px-2'
                            style={{ height: '30px', objectFit: 'contain' }}
                            src={face}
                        />
                        <div>
                            <h1 className='font-bold'>{t('contact')}</h1>
                            <span className='text-gray-400' style={{marginTop:-30}} >{thisCar?.partner_id?.registered_name}</span>
                        </div>
                    </div>
                    <div>
                        <h1 className='text-sm pr-4 text-gray-400'>{thisCar?.created_at}</h1>
                        <span className='text-sm pr-4 text-gray-400'>{t(`regions.${thisCar?.region}`)}</span>
                    </div>
                </div>
            </div>
            </div>
            <div className='fixed bottom-0 w-full'  style={{
                boxShadow: '0px 0px 30px 10px rgba(0, 0, 0, 0.1)',
                 backgroundColor: 'white',borderRadius: '15px 15px 0px 0px' }}>
            <p style={{marginLeft:12, marginRight: 12}} className="grid grid-cols-2 grid-gap">
                <Button  round onClick={(e) => dialPhone(e)} style={{backgroundColor: '#4E80EE', fontWeight: 'bold', borderRadius: 16, textTransform: 'none', fontSize: 15}} large fill>{t('call')}</Button>
                <Button onClick={reqContact}  style={{backgroundColor: '#5ad58b', fontWeight: 'bold', borderRadius: 16, textTransform: 'none', fontSize: 15}} large fill>
                    {t('torg')}
                </Button>
            </p>
            </div>
            </Page>
    );
};

export default CarCard;