import React, {useEffect, useRef, useState} from 'react';
import {Block, BlockFooter, BlockTitle, f7, Icon, Link, List, ListItem, Page, Toolbar} from "framework7-react";
import {useSelector} from "react-redux";
import sellzLogo from "../assets/sellz_logo.jpg";
import {fetchNext, getFavorites} from "../api/cars";
import {useTranslation} from "react-i18next";
import share from "../assets/icons/share.svg";
import HeartButton from "../Components/HeartButton";
import ratings from "../Enums/ratings";
import getRatings from "../Enums/ratings";

const Favourites = () => {
    const {i18n, t} = useTranslation();
    f7.views.main.history.pop();
    const lastCarRef = useRef(null);

    const observerRef = useRef(null)
    const lastElement = useRef(null)
    const [nextURL, setNextURL] = useState('');
    const [favCars, setFavCars] = useState([]);
    useEffect(() => {
        const  fetchFavs = async () => {
            const res = await getFavorites();
            setFavCars(res.results)
            setNextURL(res?.next)
        }
        fetchFavs()
    }, []);


    const fetchNextFavs =  async (next) => {
        const res = await fetchNext(next);
        setFavCars((prevState) => [...prevState, ...res.results])
        if (nextURL) {
            setNextURL(res.next)
        }
    }

    useEffect(() => {
        if (observerRef.current) observerRef.current.disconnect()

        observerRef.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && nextURL) {
                fetchNextFavs(nextURL)

            }
        }, {threshold: 0.8})

        if (lastElement.current) {
            observerRef.current.observe(lastElement.current);
        }

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };


    }, [nextURL, favCars])

    console.log(favCars)

    const handleShare = (id) => {
        const urlLink = `https://t.me/share/url?url=https://t.me/selluzautobot/selluzauto?startapp=${id}`
        window.Telegram.WebApp.openTelegramLink(urlLink)
    }



    return (
        <Page>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2, fontWeight: 'bold'}}>{t('fav')}</BlockTitle>

                        { (favCars.length === 0) ?  (<BlockFooter style={{color: 'black'}}>{t('yetEmpty')}</BlockFooter>) : (
                            <Block style={{marginTop: '20px'}} className="grid grid-cols-2 gap-y-2.5 gap-x-2.5 p-0">
                                {favCars.map((car, index) => {
                            const isLastCar = index === favCars.length - 1;
                            return (
                                <div>

                                <div
                                    ref={isLastCar ? lastCarRef : null}
                                    key={car.id}
                                    onClick={() => {f7.views.main.router.navigate(`/carcard/${car.id}/`)}}
                                    className=" relative bg-color-white rounded-xl flex-grow w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-0 m-0"
                                >
                                    <div onClick={() => handleShare(car.id)}  className="absolute shadow-lg overflow-hidden top-1 right-8 flex w-6 h-6 bg-white items-center justify-center rounded-[10px]">
                                        <img alt="share" width={15} src={share}/>
                                    </div>
                                    <HeartButton combStyle='absolute shadow overflow-hidden top-1 right-1' car={car} isFav={car.is_favorite}/>
                                    <div
                                        style={{
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'left center',
                                            height: 115,
                                            backgroundImage: `url(https://selluzauto.uz/${car?.images[0]})`,
                                        }}
                                        className="rounded-t-xl w-full"
                                    >
                                        {car.offer_type && (
                                            <div
                                                style={{backgroundColor: `${getRatings(t)[car?.offer_type].color}`}}
                                                className="whitespace-nowrap px-3 w-fit p-1 w-16 break-normal flex items-center justify-center text-[10px] rounded-tl-xl rounded-tr-md rounded-br-md text-white font-bold"
                                            >
                                                <span className="">{getRatings(t)[car?.offer_type].text}</span>
                                            </div>)} </div>
                                    <BlockTitle style={{margin:0}} className="pl-2 pt-2">{car.brand} {car.model}</BlockTitle>
                                    <p className="pl-2 text-sm">🚗 {t('outage')}: {car.year}</p>
                                    <p className="mt-0 pt-0 -mt-[5px] pl-2 text-sm">🛞 {"  "} {t('mileage')}: {car.mileage} km</p>
                                    <div className='relative flex'>
                                        <p className='pl-2 text-[9px] text-gray-500'>{car.created_at} <br/> {t(`regions.${car?.region}`)}</p>
                                        <p className='absolute text-md font-bold top-1 right-2'>{(Number.parseInt(car.price_usd).toLocaleString('en-US').replace(/,/g, ' '))} $</p>
                                    </div>
                                </div>
                                {index === favCars.length - 1 ? <div style={{marginBottom: 100}}></div> : ''}
                            </div>)

                        })}
                            </Block>
                        )}
                        <div style={{marginBottom: 100}}></div>
            <div style={{position: 'fixed', paddingBottom:80}} className="toolbar tabbar-icons toolbar-bottom">
                <div style={{marginBottom: 30}} className="toolbar-inner">
                <Link onClick={() => {f7.views.main.router.navigate("/market");window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");}} tabLink="#tab-1" >
                    <Icon f7="car_fill"></Icon>
                    <span className="tabbar-label">{t('cars')}</span>
                </Link>
                <Link onClick={() => {window.open('https://t.me/selluzpartnerbot?start=')}} tabLink="#tab-2">
                    <Icon f7={"plus_fill"}/>
                    <span className="tabbar-label">{t('publish')}</span>
                </Link>
                <Link tabLinkActive  tabLink="#tab-3">
                    <Icon f7={"heart_fill"}/>
                    <span className="tabbar-label">{t('fav')}</span>
                </Link>
                </div>
            </div>
        </Page>
    );
};

export default Favourites;